import React, { Component } from "react";
import IUser from "../../models/User";
import { IFeedValue } from "../../models/Feeds";
import Menu from "../Menu/Menu";
import OlefGrid from "../OlefGrid/OlefGrid";
import { olefService } from "../Services/OlefService";
import { IResponse } from "../../Common";
import AlertError from "../AlertError/AlertError";
import "../OlefDetail/OlefDetail.scss";
import { useUserOlefStore } from "../stores/user.store";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const initialState = {
  user: {} as IUser,
  feeds: {} as IFeedValue[],
  openError: false,
};

class Olefs extends Component<any, any> {
  state = initialState;

  componentDidMount() {
    const userJson = localStorage.getItem("user");
    const currentUser: IUser = userJson !== null ? JSON.parse(userJson) : {};

    if (currentUser) {
      this.setState({
        user: currentUser,
      });
      this.updateGrid(
        this.loadCurrentDayStart(),
        this.loadCurrentDayEnd(),
        "",
        []
      );
    }
  }

  updateGrid(start: Date, end: Date, search: string, hubs: string[]) {
    olefService
      .getFeeds(start, end, useUserOlefStore.getState()!.providerName, hubs)
      .then((response: IResponse) => {
        if (response.callSuccess) {
          const feeds = response.result
            .filter((feed: IFeedValue) => {
              if (search) {
                return (
                  (feed.broadcastType &&
                    feed.broadcastType
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (feed.broadcaster &&
                    feed.broadcaster
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (feed.name &&
                    feed.name.toLowerCase().includes(search.toLowerCase())) ||
                  (feed.type &&
                    feed.type.toLowerCase().includes(search.toLowerCase())) ||
                  (feed.wonosequence &&
                    feed.wonosequence
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                  || (feed.description?.toLowerCase().includes(search.toLowerCase()))
                )
              } else {
                return true;
              }
            })
            .sort((a: IFeedValue, b: IFeedValue) => {
              return a.name.localeCompare(b.name);
            })
            .sort((a: IFeedValue, b: IFeedValue) => {
              if (a.name === b.name) {
                return (
                  new Date(a.lineup).getTime() - new Date(b.lineup).getTime()
                );
              }
            });

          this.setState({ feeds });
        } else {
          this.setState({ openError: true });
        }
      });
  }

  handleOpenError = () => {
    this.setState({ openError: true });
  };

  handleCloseError = () => {
    this.setState({ openError: false });
  };

  loadCurrentDayStart = () => {
    return dayjs().utc().startOf("day").toDate();
  };

  loadCurrentDayEnd = () => {
    return dayjs().utc().endOf("day").toDate();
  };

  render() {
    return (
      <div style={{ flexDirection: "column", flexWrap: "initial" }}>
        <Grid container={true}>
          <Menu
            updateGrid={this.updateGrid.bind(this)}
            Start={this.loadCurrentDayStart()}
            End={this.loadCurrentDayEnd()}
          />
          <OlefGrid feeds={this.state.feeds} openError={this.handleOpenError} />

          <AlertError
            open={this.state.openError}
            handleClose={this.handleCloseError}
          />
        </Grid>
      </div>
    );
  }
}

export default Olefs;
